import { Alert, Skin } from '@etchteam/mobius';
import upperFirst from 'lodash/upperFirst';
import { CombinedError } from 'urql';

import styles from './FormErrors.module.scss';

/**
 * Attempts to clean up the error message that we get back from the API.
 * If the error is in a format that we don't know about, returns the error
 * message as is, to prevent the user getting an empty error message.
 *
 * @param message string
 * @returns string
 */
function formatErrorMessage(message: string): string {
  const graphQL = '[GraphQL]';
  const field = /"$[a-z]*"/i
    .exec(message)
    ?.reverse()
    .pop()
    .replace(/[$"]/g, '');
  const validationField =
    field && field !== 'args' ? `${upperFirst(field)}: ` : '';

  if (message.includes(';')) {
    return `${validationField}${message.split(';').slice(1).join('').trim()}`;
  }

  if (message.includes(graphQL)) {
    return `${validationField}${message.replace(graphQL, '').trim()}`;
  }

  return message;
}

/**
 * Gets error messages from a graphql error response
 *
 * @param {CombinedError} error
 * @returns {string[]}
 */
function getErrorMessages(error: CombinedError): string[] {
  if (error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map((gqle) => formatErrorMessage(gqle.message));
  }

  return [formatErrorMessage(error.message)];
}

export default function FormErrors({
  error,
}: {
  readonly error: CombinedError;
}) {
  if (!error || !error?.message) {
    return null;
  }

  const messages = getErrorMessages(error);

  return (
    <div className={styles['form-errors']}>
      <Skin skin="negative">
        <Alert>
          {messages.map((message) => (
            <p key={message}>{message}</p>
          ))}
        </Alert>
      </Skin>
    </div>
  );
}
